import React from 'react'
import * as Yup from 'yup'

import { H3 } from '@allied-solutions/affinity'

import {
  useAddFormValuesToDataLayer,
  useAddFormValuesToState,
  useDataLayerPushChanges,
  useFinalSubmission,
  useStore,
} from '../../../hooks'
import {
  Collateral,
  FileUploader,
  FormLayout,
  InfoBox,
  Name,
  ReviewLeftSide,
  Section,
} from '../../../components'

import {
  initialValues as providerInfoInitialValues,
  validationSchema as providerInfoValidationSchema,
  Fields as ProviderInfoFields,
} from './provider-info'
import {
  initialValues as deductibleInfoInitialValues,
  validationSchema as deductibleInfoValidationSchema,
  ComprehensiveFields,
  CollisionFields,
} from './deductible-info'
import {
  initialValues as policyInfoInitialValues,
  validationSchema as policyInfoValidationSchema,
  Fields as PolicyInfoFields,
} from './policy-info'

const ReviewPage = ({ location }) => {
  const { state } = useStore()
  const addFormValuesToState = useAddFormValuesToState()
  const addFormValuesToDataLayer = useAddFormValuesToDataLayer()
  const submitFinal = useFinalSubmission(location)
  useDataLayerPushChanges(location)

  // formik handle submit
  const handleSubmit = async ({ files, ...values }) => {
    values = {
      ...values,
      CollDeductible: values.CollRadio === `false` ? '' : values.CollDeductible,
      CompDeductible: values.CompRadio === `false` ? '' : values.CompDeductible,
    }
    await addFormValuesToState(values)
    addFormValuesToDataLayer()

    return submitFinal(files, values)
  }

  return (
    <FormLayout
      leftSide={{
        replace: props => <ReviewLeftSide location={location}/>,
      }}
      rightSide={{
        replace: props => (
          <>
            <InfoBox>
              <H3
                className="titleSmall"
                typeStyle="titleSmall"
                fontWeight={900}
                textAlign="left"
              >
                Review
              </H3>
              {state.borrower.ReferenceID && (
                <Section pt={0}>
                  <Name color="hint" />
                  <Collateral />
                </Section>
              )}
              <Section heading="Insurance Information" fullWidth>
                <ProviderInfoFields values={props.values} />
              </Section>
              <Section heading="Deductible Information">
                <ComprehensiveFields values={props.values} />
                <CollisionFields values={props.values} />
              </Section>
              <Section heading="Policy Information">
                <PolicyInfoFields {...props} />
              </Section>
              {!!state.form?.files?.length && (
                <Section heading="Uploaded Documents">
                  <FileUploader
                    files={state.form.files}
                    onChange={files => {
                      props.setFieldValue('files', files)
                    }}
                  >
                    <FileUploader.FilesList mt={9} />
                  </FileUploader>
                </Section>
              )}
            </InfoBox>
          </>
        ),
      }}
      location={location}
      handleSubmit={handleSubmit}
      mergeInitialValues={false}
      initialValues={{
        ...providerInfoInitialValues,
        ...deductibleInfoInitialValues,
        ...policyInfoInitialValues,
        ...state.form,
        PolicyholderEmailAddress: state.form?.PolicyholderEmailAddress || '',
      }}
      validationSchema={{
        ...providerInfoValidationSchema,
        ...deductibleInfoValidationSchema,
        ...policyInfoValidationSchema,
        PolicyholderEmailAddress: Yup.string().email(
          'Please enter a valid email address.'
        ),
      }}
    />
  )
}

export default ReviewPage
